<template>
    <div class="page-template-checkout">
        <div class="checkout-wrapper">
        <div class="loader" v-if="loaded==false"><img src="../../../public/assets/images/2021/05/loader.gif"></div>
        <div class="checkout coordonnees">
            <div class="breadrcrumb-checkout">
                <a  class="step-checkout">1. Panier</a> > <a @click="changeStep(1)" class="step-checkout active" id="step-checkout-step1" data-step="1">1. Coordonnées</a> > <a  id="step-checkout-step2" class="step-checkout step2" data-step="2">2. Paiement</a>
            </div>
            <br /><br />
            <div class="coordonnees-content" v-if="step==1">
            <div class="express-checkout" v-if="displayExpressCheckout">
                <PaypalButton :cart="cart" />
            </div>
            <div class="or" v-if="displayExpressCheckout"></div>
             <content-loader  v-if="loaded==false"
            viewBox="0 0 520 650"
            primaryColor="#f3f3f3"
            secondaryColor="#cccccc"
            >

            <rect x="0" y="20" rx="11" ry="11" width="250" height="22" />
            
            <rect x="0" y="70" rx="17" ry="17" width="520" height="34" />

            <rect x="0" y="130" rx="11" ry="11" width="250" height="22" />

            <rect x="0" y="180" rx="17" ry="17" width="250" height="34" />

            <rect x="270" y="180" rx="17" ry="17" width="250" height="34" />

            <rect x="00" y="240" rx="17" ry="17" width="520" height="34" />

            <rect x="00" y="300" rx="17" ry="17" width="520" height="34" />

            <rect x="0" y="360" rx="17" ry="17" width="250" height="34" />

            <rect x="270" y="360" rx="17" ry="17" width="250" height="34" />

            <rect x="0" y="420" rx="17" ry="17" width="250" height="34" />

            <rect x="270" y="420" rx="17" ry="17" width="250" height="34" />


            <rect x="0" y="500" rx="17" ry="17" width="200" height="34" />

            <rect x="320" y="500" rx="17" ry="17" width="200" height="34" />

            

             <rect x="00" y="580" rx="17" ry="17" width="520" height="34" />
            
        </content-loader>
            <div id="step-1" v-else>
                <h2 class="step-title" >Coordonnées 
                    <span class="content-guest" v-if="displayGuest">
                        <br /><a href="#" @click="displayLogin=true" class="connect">Vous avez déjà un compte ? Se connecter</a>
                    </span>
                </h2>
                <div class="step">
                    <div class="user-form">
                        <div class="popup-login" id="popup-login-account" v-if="displayLogin">
                        <div class="content">
                            <div class="login-form">
                                <form v-on:submit.prevent="login_account()">
                                <input type="text" id="login_account" v-model="username"  autocomplete="on" placeholder="Email">
                                <input type="password" id="password_account" v-model="password"  autocomplete="on" placeholder="******">
                                <div id="error-message-account" class="error">{{errorAccount}}</div>
                                <button id="login_button_account" >Connexion</button>
                                </form>
                                <center><a class="forgot" @click="displayForgotPassword()">Mot de passe oublié ?</a></center>
                            </div>
                        </div>
                    </div>
                    
                    <input type="email" name="email" v-model="email"  @change="changeEmail()"  autocomplete="on" id="email-input" placeholder="Email *" />
                    <span id="error-message-register-checkout">{{errorRegister}}</span>
                </div>
            </div>
            <h2 class="step-title">Adresse de livraison</h2>
            <div class="step">
                <div class="checkout-form">
                    
                    <input name="id_address" id="id_address" v-model="selectedAddress" type="hidden" />
                    <div id="contentselectaddress" v-if="addresses.length>0">
                        <label @click="displayAddresses()" class="label-address">Choisir une adresse enregistrée</label>
                        <div v-if="displayAddress==true">

                            <div @click="selectAddress(address.id,address.address1,address.postcode,address.city,address.country)" v-for="address in addresses" :key="address.id" class="select-address" :data-id="address.id" :data-address1="address.address1" :data-postcode="address.postcode" :data-city="address.city" :data-country="address.country">
                                {{address.name}}
                            </div>
                        </div>
                        <br />
                    </div>
                    <input type="text" class="small" v-model="surname" autocomplete="on" name="surname" id="surname-input" placeholder="Nom *" />
                    <input type="text" class="small" v-model="name" autocomplete="on" name="name" id="name-input" placeholder="Prénom *" />
                    <vue-google-autocomplete
                        id="map"
                        v-model="address1" 
                        classname="form-control"
                        placeholder="Adresse *"
                        v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                    
                    <input type="text" v-model="address2"  autocomplete="on" id="address2-input" placeholder="Appartement, Bâtiment, suite, etc. (facultatif)" />
                    <input type="text" class="small"  autocomplete="on" v-model="postcode" id="postcode-input" placeholder="Code postal *" />
                    
                    <input type="text" class="small"  autocomplete="on" v-model="city" id="city-input" placeholder="Ville *" />
                    <input type="text" class="small"  autocomplete="on" v-model="country" id="country-input" placeholder="Pays *" />
                    <input type="text"  v-model="phone" autocomplete="on" class="small phone-input" name="phone" id="phone-input" placeholder="Téléphone *" />
                </div>
            <div class="asterisque">* Champs obligatoires</div>
            </div>
            <div class="step">
            <div class="delivery-title">Tarif de livraison</div>
             <span class="delivery-cost">Livraison offerte</span>
            <div id="delivery_methods_table" v-if="delivery_methods">
                <table class="table shipping-methods" v-if="delivery_methods.length>0">
                <tr v-for="(method, index) in delivery_methods" :key="index">
                    <td>
                    <input type="radio" @click="chooseShipping(method.id)" name="shipping_method" class="choice_shipping" :value="method.cost" :data-id="method.id" />
                    </td>
                    <td>{{method.name}}</td>
                    <td>{{method.cost}}€</td>
                </tr>
                </table>
                <input type="hidden" id="delivery_method" v-model="delivery_method" />
                <input type="hidden" id="delivery_amount" v-model="delivery_amount" />
               
            </div>
            
            <br /><br />
            <PickupList :address="address1" :postcode="address" :city="city" />
            <input type="hidden" id="pickup_id" v-model="selectedPickup" />
            </div>
            <div class="bottom">
            <span class="label">
                <b>Sous-total du Panier</b>
                <span class="tva">TVA Incluse</span>
                <span class="delivery" style="display:none;">
                    Livraison : 
                    <span id="delivery_text" v-if="onlycollab">3 à 7</span>
                    <span id="delivery_text" v-else>7 à 15</span>
                    jours ouvrables</span>
            </span>
            <span class="total_cart"><span id="total_cart">{{total}}</span>€</span>       
            <button id="process_order_checkout" @click="processOrder()" v-if="step==1">Paiement</button></div>
            </div>
            </div>
            <div class="coordonnees-content" id="popup-payment" v-if="step==2">
                <div class="content">
                    <h2 class="step-title">Mode de paiement</h2>
                    <div class="step">
                        <div class="total-payment">
                            <div class="label">Montant total : </div><span id="amount">{{total}}</span>€
                        </div>
                        <div id="content-payment">
                            <iframe allowpaymentrequest="true" id="iframe_payment" :src="'https://api.sens-eye.fr/checkout/payment/'+this.order.id"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-review">
            <h2 class="contenudesk">Votre commande</h2>
            <h2 class="contenumob">Afficher votre commande</h2>
             <div id="order-review-content">
                <div v-for="(cart_item, index) in cart_items" :key="index">
                    <div v-if="cart_item.glass.type=='accessoire'" class='cart-item'>
                        <img class='product-img' :src="formatPhoto(photos[index])" />
                        <div class='product-details'>
                            <div class='name'>
                                {{cart_item.glass.name}} (x {{cart_item.quantity}})
                            </div>
                            <div class='item-amount'>
                            {{cart_item.amount}}€
                            </div>
                           
                            <div class='item-description'>
                                {{cart_item.glass.description}}
                            </div>
                        </div>
                    </div>
                    <div v-else-if="cart_item.glass.type=='collaboration'" class='cart-item'>
                        <img class='product-img' :src="formatPhoto(photos[index])" />
                        <div class='product-details'>
                            <div class='name'>
                                {{cart_item.glass.name}} (x {{cart_item.quantity}})
                            </div>
                            <div class='item-amount'>
                            {{cart_item.amount}}€
                            </div>
                            <div class='item-description' v-if="cart_item.product_variation_item!=null">
                                {{cart_item.product_variation_item.name}}
                            </div>
                            <div class='item-description' v-else>
                                {{cart_item.glass.description}}
                            </div>
                        </div>
                    </div>
                    <div v-else  class='cart-item'>
                        <img class='product-img' :src='formatPhoto(photos[index])' />
                        <div class='product-details'>
                            
                            <div class='name'>
                                {{cart_item.glass.name}} (x {{cart_item.quantity}})
                            </div>
                            <div class='item-amount'>
                                {{cart_item.amount}}€
                            </div>
                            
                            <div class='product-detail'>
                                
                          <label>Verres :</label> 
                           <span v-if="cart_item.glass_option!==null"> {{cart_item.glass_option.name}}</span>
                           <span v-if="cart_item.glass_type!==null && cart_item.glass_option!==null"> -</span>

                           <span v-if="cart_item.glass_type!==null"> {{cart_item.glass_type.name}}</span>
                          <span v-else> Sans correction</span>
                           <span v-if="cart_item.tint!==null"> -</span>
                           <span v-if="cart_item.tint!==null"> {{cart_item.tint.name}}</span>
                          <span class='subtotal' v-if="(cart_item.tint!=null || cart_item.glass_option!=null  || cart_item.glass_type!=null)">
                            
                            <span v-if="cart_item.tint!==null && cart_item.glass_option!==null && cart_item.glass_type!==null">{{cart_item.tint.price+cart_item.glass_option.price+cart_item.glass_type.price}}</span>

                            <span v-else-if="cart_item.tint!==null && cart_item.glass_option!==null">{{cart_item.tint.price+cart_item.glass_option.price}}</span>

                            <span v-else-if="cart_item.glass_option!==null">{{cart_item.glass_option.price}}</span>

                            <span v-else-if="cart_item.tint!==null">{{cart_item.tint.price}}</span>

                            €
                          </span>
                        </div>
                        <div class='product-detail'>
                          <label>Couleur :</label>
                          {{cart_item.color.name}}
                          <span v-if="cart_item.color.price" class='subtotal'>{{cart_item.color.price}}€</span>
                        </div>
                       
                        <div class='product-detail'>
                          <label>Branche :</label>
                          {{cart_item.branch.name}}
                          <span class='subtotal'  v-if="cart_item.branch.price">{{cart_item.branch.price}}€</span>
                        </div>
                        <div class='product-detail'>
                          <label>Texture :</label>
                          {{cart_item.texture.name}}
                          <span class='subtotal'  v-if="cart_item.texture.price">{{cart_item.texture.price}}€</span>
                        </div>

                        <div class='product-detail' v-if="cart_item.text!=null && cart_item.text!=''">
                          <label>Gravure :</label>
                          {{cart_item.text}}
                          <span class='subtotal'>10€</span>
                        </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="promotion">
                <input type="text" name="promotion" id="promotion" v-model="promocode" placeholder="Code promotionnel" /> 
                <button type="submit" class="arrow" @click="applyPromotion"></button>
            </div>
            <div id="promotion-error" class="error">{{promotionError}}</div>
            <div class="promotion_content" v-if="displayPromotion">
            Promotion appliquée !<br /><br />
            Sous-total panier : <span id="subtotal">{{total}}</span>€<br />
            Montant promotion : -<span id="total_promotion">{{amount_promotion}}</span>€<br /><br />
            </div>
            <div class="subtotals">
                <div class="subtotal">
                    <span class="subtotal-label">Sous-total du panier</span>
                    <span id="cart-st" class="subtotal-amount">{{total}}</span> 
                </div>
                <div class="subtotal">
                    <span class="subtotal-label">Tarif de livraison (Colissimo)</span>
                    <span class="subtotal-amount">0.00€</span>
                </div>

                <br /><br />
                <div class="delivery-steps">
                    <span class="delivery-step"><span class="number">1</span> Préparation de la commande entre 5 à 15 jours ouvrables</span>
                    <span class="number">2</span> Colissimo - Expedition en 48h<br />
                </div>
                
            </div>
            <div class="total"><span class="total-label">Total : </span><span  class="total-amount"><span id="total">{{total}}</span>€</span></div>
            <br /><br />
        
            </div>
        </div>

    <br /><br /><br /><br />
    <Footer :language="language" :displayModalMenu="displayModalMenu" />
  </div>
  
</template>

<script>
import sendinblue  from 'sendinblue';
import fbq  from 'fbq';
import google  from 'google';
import Footer from '../../components/layout/Footer.vue'
import PickupList from '../../components/elements/PickupList.vue'
import jQuery  from 'jquery';
import {Auth, APIRequest, conversionApi} from "@simb2s/senseye-sdk";
import emitter from 'tiny-emitter/instance'
import PaypalButton from '../../components/elements/PaypalButton.vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete' 
import { ContentLoader } from 'vue-content-loader'
export default {
  name: 'CheckoutNew',
  components: {
    Footer,
    PickupList,
    PaypalButton,
    VueGoogleAutocomplete,
    ContentLoader
  },
  data() {
    return {
      randomNumber:1,
      displayLogin:false,
      promocode:'',
      address1:'',
      address2:'',
      postcode:'',
      selectedPickup:'',
      city:'',
      country:'',
      name:'',
      errorRegister:'',
      error:'',
      errorAccount:'',
      surname:'',
      email:'',
      username:null,
      password:null,
      available_methods:[],
      cart:null,
      cart_weight:0,
      delivery_methods:[],
      delivery_method:null,
      delivery_amount:null,
      loaded:false,
      weight_cart:0,
      phone:'',
      order:null,
      cart_items:[],
      order_items:[],
      onlycollab:true,
      photos:[],
      step:1,
      displayGuest:true,
      addresses:[],
      displayAddress:false,
      selectedAddress:'none',
      displayPromotion:false,
      promotionError:'',
      total:0,
      amount_promotion:0,
      autocomplete:null,
      displayExpressCheckout:false,
    }
  },
  props:["displayModalMenu","language"],
  watch:
  {
   
    country:function(){
        this.loadPickups();
        this.updateCartAddress();
    },
    postcode:function(){
        this.loadPickups();
    },
    city:function(){
        this.loadPickups();
    },
    address1:function(){
        this.loadPickups();
    },
    phone:function(){
        this.phone = this.phone.replace(/[^0-9]/g, '');
    },
  },
  methods:{
    changeEmail() { 
        sendinblue.identify(this.email);
    },
    getAddressData(addressData, placeResultData, id) {
      this.address1=placeResultData.address_components[0].short_name + ' '+placeResultData.address_components[1].long_name;
      this.city=placeResultData.address_components[2].long_name;
      this.country=placeResultData.address_components[5].long_name;
      this.postcode=placeResultData.address_components[6].long_name;
    },
    displayForgotPassword()
    {
        emitter.emit('displayforgotpassword');
    },
    selectPickup(id)
    {
        this.selectedPickup=id
    },
    processOrder()
    {
        this.loaded=false
        this.displayPayment();
    },
    selectAddress(id,address1,postcode,city,country){
        if(this.selectedAddress==id)
        {
        this.selectedAddress='none'
        }
        else
        {
        this.selectedAddress=id
        this.address1=address1
        this.postcode=postcode
        this.city=city
        this.country=country
        jQuery('.select-address').attr('class','select-address')
        jQuery(this).attr('class','select-address active')
        }
    },
    loadPickups()
    {

    },
    changeStep(step)
    {
        this.step=step
        jQuery('.step-checkout').removeClass('active');
        jQuery('#step-checkout-step'+step).addClass('active');
        if(this.step==2)
        {
            this.displayExpressCheckout=false
        }
        else
        {
            this.displayExpressCheckout=true
        }
        jQuery('html, body').animate({
            scrollTop: 0
        }, 500);                 
    },
    formatPhoto(media) {
        let photo=media.replace('[','');
        photo=photo.replace(']','');
        photo=photo.replace("'",'');
        photo=photo.replace('"','');
        return photo
    },
    async createAddress()
    {
        let Parameters = {};
        Parameters.name = "Domicile";
        Parameters.first_name = this.surname;
        Parameters.last_name = this.name;
        Parameters.phone = this.phone;
        Parameters.address1 = this.address1;
        Parameters.address2 = this.address2;
        Parameters.postcode = this.postcode;
        Parameters.city = this.city;
        Parameters.default_address=true;
        Parameters.country = this.country;
        if(localStorage.id_user_senseye_auth)
        {
            Parameters.user={};
            Parameters.user.id=localStorage.id_user_senseye_auth;
        }
        let address=APIRequest.create('addresses', Parameters);
        return address;
    },
    async displayPayment() { 
        if(jQuery('#pickup-list').is(':visible') && this.selectedPickup=='')
        {
            alert('Vous devez sélectionner un point relais !');
        }
        else
        {
            if(this.address1!='' && this.postcode !='' && this.city!='' && this.country!='' && this.phone!='')
            {
                
                this.cart=await APIRequest.detail('carts',localStorage.cart_senseye_id)
                this.cart_items=this.cart.cart_items;
                this.order=await APIRequest.detail('orders',localStorage.order_senseye_id)
                fbq('track', 'AddPaymentInfo', {currency: "EUR", value: this.cart.amount});
                await conversionApi.addEvent('AddPaymentInfo')
                this.order_items=this.order.order_items;
                let Parameters = {};
                Parameters.amount = this.order.amount;
                Parameters.woo_order =  this.order.woo_order;
                Parameters.shapediver_id =  this.order.shapediver_id;
                Parameters.address = {};
                Parameters.phone =this.phone;
                Parameters.user={};
                Parameters.user.id=localStorage.id_user_senseye_auth;
                Parameters.order_items = JSON.parse(localStorage.order_items_senseye);
                Parameters.delivery_method = this.delivery_method;
                Parameters.delivery_amount = this.delivery_amount;
                Parameters.promotion = this.order.promotion;
                Parameters.cart = {};
                Parameters.cart.id = localStorage.cart_senseye_id;
                Parameters.pickup_point=this.selectedPickup;
                
                Parameters.user={};
                Parameters.user.id=localStorage.id_user_senseye_auth;

                if(this.selectedAddress && this.selectedAddress!='none')
                {
                    Parameters.address.id = this.selectedAddress;             
                }
                else
                {              
                    let address=await this.createAddress();
                    let id_address=address.id;
                    Parameters.address = {};
                    Parameters.address.id = id_address;
                }
            

                try {
                    this.order=await APIRequest.update('orders',localStorage.order_senseye_id, Parameters)
                } catch (error) {
                    this.error="Une erreur s'est produite"
                }
                this.changeStep(2)
                this.loaded=true
            }
            else
            {
                alert('Tous les champs sont obligatoires !');
                this.loaded=true
            }
        }
    },
    async registerUser()
    {
        let password= Math.random().toString(36).substring(7);
        let user=emitter.emit('register',this.email,password,this.name,this.phone,this.surname,this.email)
	  	if(user!=null)
        {
            Parameters.username = this.email;
            Parameters.password = password;
            let user_login=emitter.emit('login', this.username, this.password);
            if(user_login!=null)
            {
                this.user=user_login    
                this.user=await Auth.get_user(this.email)  
                localStorage.id_user_senseye_auth=this.user.id;
                this.errorRegister=''
            }
        }
        else
        {
        this.errorRegister="Une erreur s'est produite. Veuillez recommencer."
        }
       
    },
    async login_account(){  
        this.loaded=false
        emitter.emit('login', this.username, this.password);
        
    },
    async userLogin (user) {
        if(user!=null)
        {
            this.errorAccount="";
            user=this.parseJwt(localStorage.token_senseye_auth);
            this.user=await Auth.get_user(user.username)
            localStorage.id_user_senseye_auth=this.user.id;
            this.email=this.user.email
            this.name=this.user.name
            this.surname=this.user.surname
            this.getAddresses()
            this.error=''
            this.loaded=true
        }
        else
        {
            this.loaded=true
            this.errorAccount="Mot de passe ou email incorrect.";
        }

    },
    async updateCartAddress(){
        
      let Parameters={};
      Parameters.country=this.country;
      try {
          this.available_methods=await APIRequest.list_shipping_options(Parameters);
      } catch (error) {
          this.available_methods=[]
      }
      if(this.available_methods==undefined)
      {
          this.available_methods=[]
      }
      this.cart=await APIRequest.detail('carts', localStorage.cart_senseye_id);                
      let nb_items=0;
      for(var p=0;p<this.cart.cart_items.length;p++)
      {
          nb_items=nb_items*1+this.cart.cart_items[p].quantity*1;
      }
      this.weight_cart=nb_items*0.25;
      this.delivery_methods=[];
      let method_nb=0;
      if(this.available_methods.length>0)
      {
        for(var i=0;i<this.available_methods.length;i++)
        {
            if(this.available_methods[i].enabled==true)
            {
            let shipping_rates=this.available_methods[i].settings.shipping_rates.value;
            let shipping_cost=null;
            for(var j=0;j<shipping_rates.length;j++)
            {
                if(shipping_rates[j].min_weight<=this.weight_cart && shipping_rates[j].max_weight>this.weight_cart)
                {
                    shipping_cost=shipping_rates[j].price;
                }
            } 
            if(shipping_cost!=null)
            {
                this.delivery_methods[method_nb]={};
                this.delivery_methods[method_nb].cost=shipping_cost;
                this.delivery_methods[method_nb].id=this.available_methods[i].method_id;
                this.delivery_methods[method_nb].name=this.available_methods[i].method_title;
                method_nb++;
            }
            }
        }    
        this.delivery_method=this.delivery_methods[0].id;
        this.delivery_amount=this.delivery_methods[0].cost;  
      }
    },
    async chooseShipping(id_method, method_amount)
    {
      this.delivery_amount=id_method;
      this.delivery_amount=method_amount;
      this.cart=await APIRequest.detail('carts', localStorage.cart_senseye_id)
      let Parameters = {};
      Parameters.amount = this.cart.amount;
      Parameters.delivery_method = this.delivery_method; 
      Parameters.delivery_amount = this.delivery_amount;
      Parameters.cart_items = this.cart.cart_items;
      Parameters.promotion = this.cart.promotion;
      Parameters.user=this.cart.user;
      this.cart=await APIRequest.update('carts', localStorage.cart_senseye_id, JSON.stringify(Parameters))
      this.loaded=true                              
    },
    async applyPromotion() {
        this.loaded=false
        let promocode=this.promocode;
        let promotion_id=null;
        if(promocode!="")
        {
            let promotionexist=false;
            this.order=await APIRequest.detail('orders',localStorage.order_senseye_id)
            let promotions=await APIRequest.list('promotions')
            promotions=promotions[0]              
            for(var i=0;i<promotions.length;i++)
            {
                if(promocode.toLowerCase()==promotions[i].code.toLowerCase())
                {
                    promotionexist=true;
                    promotion_id=promotions[i].id;
                    if(promotions[i].active==true)
                    {
                        let Parameters = {};
                        Parameters.amount = this.order.amount;
                        Parameters.delivery_amount = this.order.delivery_amount;
                        Parameters.delivery_method = this.order.delivery_method;
                        Parameters.woo_order = this.order.woo_order;
                        Parameters.promotion = {};
                        Parameters.promotion.id = promotion_id;
                        Parameters.shapediver_id = this.order.shapediver_id;
                        Parameters.address = this.order.address;
                        Parameters.phone = this.order.phone;
                        Parameters.user=this.order.user;
                        Parameters.order_items = JSON.parse(localStorage.order_items_senseye);
                        this.order=await APIRequest.update('orders',localStorage.order_senseye_id,JSON.stringify(Parameters))
                        this.cart=await APIRequest.detail('carts',localStorage.cart_senseye_id)           
                        Parameters = {};
                        Parameters.amount = this.cart.amount;
                        Parameters.delivery_method = this.cart.delivery_method;
                        Parameters.delivery_amount = this.cart.delivery_amount;                                                                        
                        Parameters.cart_items = this.cart.cart_items;
                        Parameters.promotion = {};
                        Parameters.promotion.id = promotion_id;
                        Parameters.user=this.order.user;
                        this.cart=null
                        this.cart=await APIRequest.update('carts',localStorage.cart_senseye_id,JSON.stringify(Parameters))                        
                        if(this.cart.promotion)
                        {
                            
                            let amount_promotion=(this.cart.amount/100) * this.cart.promotion.amount;
                            let new_total=this.cart.amount - amount_promotion;
                            this.displayPromotion=true
                            this.total=new_total.toFixed(2)
                            this.amount_promotion=amount_promotion.toFixed(2)
                            new_total=new_total*1+this.delivery_amount*1;
                            let rand = Math.floor((Math.random()*1000000)+1);
                            this.randomNumber=rand
                            if(document.getElementById('iframe_payment'))
                            {
                                let iframepaymenturl=document.getElementById('iframe_payment').src;
                                document.getElementById('iframe_payment').src=iframepaymenturl;
                            }
                        }  
                        
                       
                        this.loaded=true
                    }
                    else
                    {
                        alert("Le code promo n'est plus valide");
                        this.loaded=true
                    }
                }
            }
            if(promotionexist==false)
            {
                alert("Le code promo n'existe pas");
                this.loaded=true
            }                                     
        }
        else
        {
            alert('Le champs est vide !');
            this.loaded=true
        }
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    displayAddresses()
    {
        if(this.displayAddress==false)
        {
            this.displayAddress=true;
        }
        else
        {
            this.displayAddress=false;
        }
    },
    async getAddresses()
    {
        let addresses=await APIRequest.list('addresses',localStorage.id_user_senseye_auth)
        this.addresses=addresses[0]
        if(this.addresses.length>0)
        {
            this.address1=this.addresses[0].address1
            this.postcode=this.addresses[0].postcode
            this.city=this.addresses[0].city
            this.country=this.addresses[0].country
            this.displayAddress=false;
        }
    }
    
      
  },
  async mounted()
  { 
    emitter.on('selectpickup', function (id) {
        this.selectedPickup(id)
    }, this);
   
    this.loaded=false
    if(this.$route.params.token!=null)
    {
   
        localStorage.token_senseye_auth=this.$route.params.token
        let user=this.parseJwt(localStorage.token_senseye_auth);
        
        this.user=await Auth.get_user(user.username)

     
        localStorage.id_user_senseye_auth=this.user.id;
    }
    if(this.$route.params.id)
    {
        localStorage.cart_senseye_id=this.$route.params.id
        this.cart=await APIRequest.detail('carts',localStorage.cart_senseye_id)
        this.displayExpressCheckout=true
        fbq('track', 'InitiateCheckout', {currency: "EUR", value: this.cart.amount});
        await conversionApi.addEvent('InitialeCheckout')
        this.loaded=true
        this.cart_items=this.cart.cart_items;
        this.order_items=[];
        for(let i=0;i<this.cart_items.length;i++)
        {
            let order_item={};
            order_item.id=this.cart_items[i].id;
            this.order_items[i]=order_item;
        }
        localStorage.order_items_senseye=JSON.stringify(this.order_items);   
        this.onlycollab=true;
        this.photos=[]
        this.total=this.cart.amount.toFixed(2)     
        for (let i=0;i<this.cart_items.length;i++)
        {
            if(this.cart_items[i].glass.type=="accessoire")
            {
            
                    this.photos[i]=this.cart_items[i].glass.photos[0]
                

            }
            else if(this.cart_items[i].glass.type=="collaboration")
            {
               if(this.cart_items[i].product_variation_item!=null)
                {
                    this.photos[i]=this.cart_items[i].product_variation_item.photos[0]
                }
                else
                {
                    this.photos[i]=this.cart_items[i].glass.photos[0]
                }
   
            }
            else
            {
                this.onlycollab=false;
                if(this.cart_items[i].tint!==null)
                {
                    var request = "?glass="+this.cart_items[i].glass.id+"&color="+this.cart_items[i].color.id+'&texture='+this.cart_items[i].texture.id+'&branch='+this.cart_items[i].branch.id+'&tint='+this.cart_items[i].tint.id;
                }
                else
                {
                    var request = "?glass="+this.cart_items[i].glass.id+"&color="+this.cart_items[i].color.id+'&texture='+this.cart_items[i].texture.id+'&branch='+this.cart_items[i].branch.id+'&tint=5';
                }
            
                let photos= await APIRequest.list_with_request('photos',request)
                let photo=photos[0]

                this.photos[i]=photo[0].photo

            }
        }
        if(this.cart.promotion)
        {
            let amount_promotion=(this.cart.amount/100) * this.cart.promotion.amount;
            let new_total=this.cart.amount - amount_promotion;
            this.total=new_total.toFixed(2)
            this.amount_promotion=amount_promotion.toFixed(2)
            jQuery('.order-review #total').html(new_total.toFixed(2));
            jQuery('.order-review #cart-st').html(new_total.toFixed(2)+'€');
            jQuery('.order-review #subtotal').html(this.cart.amount.toFixed(2));
            jQuery('.order-review #total_promotion').html(amount_promotion.toFixed(2));
            this.displayPromotion=true
            let delivery_cost=this.delivery_amount;
            new_total=new_total*1+delivery_cost*1;
            jQuery('.checkout .bottom .total_cart #total_cart').html(new_total.toFixed(2));
            jQuery('.total-payment #amount').html(new_total.toFixed(2));
        }
        else
        {
            jQuery('.order-review #total').html(this.cart.amount.toFixed(2));
            jQuery('.order-review #cart-st').html(this.cart.amount.toFixed(2)+'€');
            let delivery_cost=jQuery('#delivery_amount').val();
            let new_amount=this.cart.amount*1+delivery_cost*1;
            jQuery('.checkout .bottom .total_cart #total_cart').html(new_amount);
            jQuery('.total-payment #amount').html(this.cart.amount);
        }
        let Parameters = {};
        Parameters.amount = this.cart.amount;
        Parameters.shapediver_id = 0;
        Parameters.woo_order = 0;
        Parameters.delivery_amount = this.cart.delivery_amount;
        Parameters.delivery_method = this.cart.delivery_method;
        Parameters.cart = {};
        Parameters.cart.id = this.cart.id;
        Parameters.phone = jQuery('#phone-input').val();
        Parameters.order_items = JSON.parse(localStorage.order_items_senseye);
        Parameters.promotion = this.cart.promotion;
        if(localStorage.token_senseye_auth)
        {
           
            this.displayLogin=false
            this.displayGuest=false
            try {
                let user=this.parseJwt(localStorage.token_senseye_auth);
                this.user=await Auth.get_user(user.username)
                localStorage.id_user_senseye_auth=this.user.id;
                Parameters.user={};
                Parameters.user.id=localStorage.id_user_senseye_auth;
                this.email=this.user.email
                this.name=this.user.name
                this.surname=this.user.surname
                this.getAddresses()
                this.error=''
                this.loaded=true 
            } catch (error) {
                this.loaded=true
                this.error='Une erreur est survenue'
            }
        }
        this.order=await APIRequest.create('orders', JSON.stringify(Parameters))         
        localStorage.order_senseye_id=this.order.id;             
        let items=JSON.parse(localStorage.order_items_senseye);
        for(var i=0;i<items.length;i++)
        {
            let order_item= await APIRequest.detail('order-items',items[i].id);     
            Parameters = order_item;
            Parameters.order = {};
            Parameters.order.id=localStorage.order_senseye_id;
            Parameters.glass = {};
            Parameters.glass.id=order_item.glass.id;
            order_item= await APIRequest.update('order-items',order_item.id,JSON.stringify(Parameters))                  
        } 
    }
    else
    {
        alert('aucun panier');
    }    
   
    jQuery('.order-review h2.contenumob').click(function(){

        if(jQuery('.order-review #order-review-content').is(':visible'))
        {
            jQuery('.order-review #order-review-content').slideUp();
        }
        else
        {
            jQuery('.order-review #order-review-content').slideDown();
        }
    });
  }
  
}
</script>
<style  scoped>

a.connect
{
    color: #0000FF;
    font-size: 11px;
}
.checkout .express-checkout::after
{
	
	background: #fff;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    top: -12px;
    left: 50%;
	font-weight:700;
    transform: translateX(-50%);
    content: "Paiement express";
 
}
.checkout .express-checkout
{
	
	border: 1px solid black;
    /* padding: 15px; */
    padding-top: 10px;
    padding-bottom: 10px;
	position:relative;
}

table.shipping-methods tr,
table.shipping-methods td,
table.shipping-methods
{
	border:0;
}
#pickup-list table td,
#pickup-list table tr,
#pickup-list table
{
	text-align:left;
	border:0;
}
#pickup-list
{
		text-align:left;
	margin-bottom:20px;
	max-height:350px;
	overflow:auto;
}
#pickup-modal #widget-container
{
	width:70%;
	max-width:1100px;
	overflow:auto;
	position:absolute;
	max-height:100vh;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
#pickup-modal
{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:rgba(0,0,0,0.5);
	
	z-index:999999;
}
.subtotal
{
	clear:both;
	display:block;
	width:100%;
}
.subtotals
{
	padding-top:20px;
	padding-bottom:40px;
	border-top:2px solid #eee;
	border-bottom:2px solid #eee;
}

.subtotal .subtotal-amount
{
	float:right;
}
.subtotal .subtotal-label
{
	float:left;
}
#error-message-register-checkout,.error
{
	color:orange;
}
.asterisque
{
	font-size:10px;
}
#contentselectaddress
{
	text-align: left;
	font-size:13px;
}
.select-address
{
	position:relative;
	
	font-size:13px;
	margin-top:5px;
	cursor:pointer;
	transition:all 0.3s;
	padding:5px 20px;
}

.select-address:hover
{
	background:rgba(0,0,0,0.05)
}

.select-address.active:before
{
	background:#000;
}
.select-address:before
{
	position:absolute;
	left:3px;
	border-radius:50%;
	border:1px solid black;
	background:transparent;
	width:12px;
	height:12px;
	content:' ';
	top:7px;
}
.label-address
{
	color:#3572d4;
	cursor:pointer;
	font-size:13px;
}
.delivery-step
{
    display: block;
    margin-bottom:3px;
}
.delivery-steps 
{
    font-size:12px;
    text-align: left;
}
.delivery-steps .number
{
    background:#000;
    border-radius:50%;
    padding:5px 8px;
    display: inline-block;
    margin-right:1px;
    font-size:9px;
    color:#fff;
}
#popup-payment
{
    display:block;
}
.product-detail .subtotal
{
    float:right;
    clear:none;
    width:auto;
    display: inline-block;
}
.product-detail
{
    clear:both;
    display:block;
    width:100%;
}
.popup-login
        {
            animation: fadeanimation 0.4s ease;
        }
</style>

